import pic01 from '../../../../assets/team/pic_01.jpg';
import pic02 from '../../../../assets/team/pic_02.jpg';
import pic04 from '../../../../assets/team/pic_04.jpg';

const TeamList = [
	{
		id: 1,
		name: 'Oksana Prokhorova',
		title: 'CEO, Founder',
		imgLink: pic01,
		facebook: 'https://www.facebook.com/profile.php?id=100012202903705',
		linkedin: 'https://www.linkedin.com/in/oksana-prokhorova',
	},
	{
		id: 2,
		name: 'Volodymyr Prokhorov',
		title: 'CTO, Founder',
		imgLink: pic02,
		facebook: '',
		linkedin: 'https://www.linkedin.com/in/volodymyr-prokhorov/',
	},
	{
		id: 4,
		name: 'Lyudmila Starzhynska',
		title: 'CRO, Founder',
		imgLink: pic04,
		facebook: '',
		linkedin: 'https://www.linkedin.com/in/ludmila-starzhynska/',
	},
];

export { TeamList };
