import { NavbarComponent } from '../../common/Navbar/Navbar';

import './Header.css';

function HeaderComponent() {
	return (
		<header className='container-fluid p-1 d-flex flex-column justify-content-between'>
			<NavbarComponent />
			<div className='header-text text-center mb-3'>
				<h2>Organic Fertilizers and Compost from Earthworms</h2>
				<h4 className='d-none d-sm-block'>
					Sustainable Soil Enrichment through Recycled Organic Waste
				</h4>
			</div>
		</header>
	);
}

export { HeaderComponent };
