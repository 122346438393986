import { CallToAction } from '../CallToAction/CallToAction';

function FAQ() {
	const accordion = (
		<section className='row p-3'>
			<div className='container'>
				<h2 className='text-center'>FAQ</h2>
			</div>
			<div className='container'>
				<div className='accordion accordion-flush' id='accordionFlushExample'>
					<div className='accordion-item'>
						<h2 className='accordion-header' id='flush-headingOne'>
							<button
								className='accordion-button collapsed'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#flush-collapseOne'
								aria-expanded='false'
								aria-controls='flush-collapseOne'
							>
								What are the end results of using a composting booster for food
								and organic waste in farming?
							</button>
						</h2>
						<div
							id='flush-collapseOne'
							className='accordion-collapse collapse'
							aria-labelledby='flush-headingOne'
							data-bs-parent='#accordionFlushExample'
						>
							<div className='accordion-body'>
								The use of a composting booster for food and organic waste in
								farming results in several end products that are beneficial for
								farmers. Firstly, the booster helps to break down the waste
								efficiently, resulting in a nutrient-rich compost known as
								vermigumus. This compost is high in essential nutrients that are
								important for soil health and can be used to enhance crop growth
								and yield. Additionally, the process of using the composting
								booster also produces native worms that have been crossbred with
								eisenia fetida worms. These worms can be used as bait for
								fishing and will hatch from cocoons, providing a continuous
								supply of bait for the user. Moreover, the use of a composting
								booster helps to improve the overall quality of soil on the
								property, making it more fertile and conducive for plant growth.
								This can lead to increased crop yields and better overall
								agricultural output. Overall, the use of a composting booster
								for food and organic waste is a valuable tool for farmers
								looking to improve soil health and boost crop yields.{' '}
							</div>
						</div>
					</div>
					<div className='accordion-item'>
						<h2 className='accordion-header' id='flush-headingTwo'>
							<button
								className='accordion-button collapsed'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#flush-collapseTwo'
								aria-expanded='false'
								aria-controls='flush-collapseTwo'
							>
								What are the components of the composting booster for food and
								organic waste used by farmers?
							</button>
						</h2>
						<div
							id='flush-collapseTwo'
							className='accordion-collapse collapse'
							aria-labelledby='flush-headingTwo'
							data-bs-parent='#accordionFlushExample'
						>
							<div className='accordion-body'>
								The composting booster for food and organic waste used by
								farmers consists of several key components that work together to
								maximize the benefits of the process. Firstly, the booster
								contains Eisenia fetida cocoons, which are the eggs of a species
								of earthworm commonly used in composting. These cocoons hatch
								into worms that help to break down the waste and create
								nutrient-rich compost. Additionally, the booster also contains
								Eisenia fetida enzymes that help to speed up the breakdown of
								the waste and facilitate the composting process. The booster
								also contains bacteria that help to further break down the waste
								and create a healthy environment for the worms to thrive.
								Lastly, the booster includes humates, which are organic
								compounds that help to improve soil structure and fertility.
								When used together in a balanced manner, these components help
								to create a composting booster that is highly effective in
								breaking down food and organic waste and creating high-quality
								compost that is beneficial for farmers. Overall, the composting
								booster is a valuable tool for farmers looking to improve soil
								health and boost crop yields.
							</div>
						</div>
					</div>
					<div className='accordion-item'>
						<h2 className='accordion-header' id='flush-headingThree'>
							<button
								className='accordion-button collapsed'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target='#flush-collapseThree'
								aria-expanded='false'
								aria-controls='flush-collapseThree'
							>
								Can the composting booster for food and organic waste used by
								farmers create unpleasant odors?
							</button>
						</h2>
						<div
							id='flush-collapseThree'
							className='accordion-collapse collapse'
							aria-labelledby='flush-headingThree'
							data-bs-parent='#accordionFlushExample'
						>
							<div className='accordion-body'>
								One common concern when it comes to composting food waste and
								organic matter is the potential for unpleasant odors to develop.
								However, with the use of the composting booster for food and
								organic waste, this should not be a significant issue. The key
								is to follow the technology and process guidelines for using the
								booster, which involves saturating the composted substrate with
								Eisenia fetida enzymes. These enzymes work to break down the
								waste quickly and efficiently, without producing any noticeable
								odor. As long as the composting process is properly managed and
								the technology is used correctly, the composting booster should
								not result in any unpleasant smells. This is a significant
								advantage for farmers who may be concerned about the impact of
								composting on their property or in nearby areas. Overall, the
								composting booster is a reliable and effective solution for
								managing food waste and organic matter without causing any
								significant odors.
							</div>
						</div>
					</div>
				</div>
				<div className='p-3 text-center'>
					<CallToAction />
				</div>
			</div>
		</section>
	);
	return accordion;
}

export { FAQ };
