function ButtonComponent(props) {
	const button = (
		<button
			type={props.type ? props.type : 'button'}
			id={props.id}
			onClick={props.onClick}
			className={props.thisClass}
			disabled={props.disabled}
		>
			{props.btnText}
		</button>
	);
	return button;
}

export { ButtonComponent };
