import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ModalComponent({ isShow, initModal }) {
	// const [isShow, invokeModal] = React.useState(false);

	// const initModal = () => {
	// 	return invokeModal(!isShow);
	// };

	return (
		<Modal show={isShow} centered>
			<Modal.Header closeButton onClick={initModal}>
				<Modal.Title>Thank You for Your Request!</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Your request has been received and we appreciate your interest in our
				products/services. Our team is currently reviewing your request and will
				get back to you shortly. If you have any further questions or concerns,
				please don't hesitate to contact us. Thank you for choosing us!
			</Modal.Body>
			<Modal.Footer>
				<Button variant='success' onClick={initModal}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
export { ModalComponent };
