import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Facebook } from 'react-bootstrap-icons';
// import { Twitter } from 'react-bootstrap-icons';
import { Telegram } from 'react-bootstrap-icons';
import './Navbar.css';
import logo from 'assets/logo.png';

function NavbarComponent() {
	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const toggleMenu = () => setIsNavCollapsed((isOpen) => !isOpen);
	const navLinks = (
		<nav className='navbar navbar-expand-sm pt-0'>
			<div className='container-fluid px-2'>
				<a className='navbar-brand mx-auto' href='/'>
					<img src={logo} className='logo card-img-top' alt='Logo' />
				</a>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded={!setIsNavCollapsed ? true : false}
					aria-label='Toggle navigation'
					onClick={toggleMenu}
				>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div
					className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}
					id='navbarSupportedContent'
				>
					<ul className='nav navbar-nav mx-auto'>
						<li className='nav-item'>
							<NavLink className='nav-link' to='/' onClick={toggleMenu}>
								Home
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink className='nav-link' to='/about-us' onClick={toggleMenu}>
								About us
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink className='nav-link' to='/store' onClick={toggleMenu}>
								Shop
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink className='nav-link' to='/contact' onClick={toggleMenu}>
								Contact Us
							</NavLink>
						</li>
					</ul>
					<ul className='navbar-nav ms-auto d-none d-lg-inline-flex'>
						<li className='nav-item mx-2'>
							<a
								className='nav-link text-dark h5'
								href='https://www.facebook.com/WormNET.pro'
								target='blank'
							>
								<Facebook />
							</a>
						</li>
						{/* <li className='nav-item mx-2'>
							<a className='nav-link text-dark h5' href='/' target='blank'>
								<Twitter />
							</a>
						</li> */}
						<li className='nav-item mx-2'>
							<a
								className='nav-link text-dark h5'
								href='https://t.me/WormNET'
								target='_blank'
								rel='noreferrer'
							>
								<Telegram />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
	return navLinks;
}

export { NavbarComponent };
