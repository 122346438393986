import { timelineData } from './TimelineData';
import './Timeline.css';

function Timeline() {
	const timelineList = timelineData.map((item, index) => (
		<li className='timeline-item mb-5' key={index}>
			<h5>{item.title}</h5>
			<p className='text-muted mb-2'>{item.year}</p>
			<p className='text-muted'>{item.description}</p>
		</li>
	));

	const timelineBlock = (
		<section className='row p-3'>
			<div className='row d-flex justify-content-center'>
				<div className='col-md-10 col-xl-8 text-center'>
					<h2 className='mb-4'>Our History</h2>
					<p className='mb-4 pb-2 mb-md-5 pb-md-0'>
						"Staying Ahead of the Curve: How Our Company Adapted to Changing
						Industry Trends"
					</p>
				</div>
			</div>
			<div>
				<ul className='timeline'>{timelineList}</ul>
			</div>
		</section>
	);

	return timelineBlock;
}

export { Timeline };
