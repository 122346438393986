const timelineData = [
	{
		title: 'Biomass Processing Begins',
		year: '2013',
		description:
			'Constructed plant to process agricultural waste into solid fuel briquettes, including a unique technological line for working with mixed raw materials.',
	},
	{
		title: 'Plant reaching design capacity',
		year: '2014',
		description:
			'In 2014, we achieved a significant milestone by successfully achieving design pressure to the plant, which allowed us to customize our products and work closely with our client base. Our focus on popularizing renewable energy sources like briquettes made from woodworking and agricultural waste helped us to not only diversify our offerings but also contribute towards a sustainable future. This led to an increased demand for our products and helped establish us as a reliable supplier in the industry.',
	},
	{
		title:
			'Participation in the Partnership with Germany program: "Reed is the building material of the future',
		year: '2015',
		description:
			'In 2015, we participated in the Partnership with Germany program and presented their project, "Reed - is the material of the future," which uses reeds as a renewable energy source. The Kherson plant produces fuel briquettes, pellets, particle boards, insulating materials, mineral fertilizers, and reed-based food additives from reeds. Director Oksana Prokhorova (Shevchenko) successfully sold the first two tons of cane briquettes to German enterprises and is seeking partners for deep processing of reed.',
	},
	{
		title: 'Working with communal enterprises',
		year: '2016 - 2018',
		description:
			'Designing and constructing efficient solid fuel boiler houses in cooperation with communal enterprises of the Kherson region was the major accomplishment of our enterprise between 2016 and 2018. We provided the region with a sustainable and eco-friendly solution for heating, utilizing locally sourced fuel such as pellets and briquettes made from reed and other biomass. Our team ensured that the boiler houses are not only energy-efficient but also cost-effective, enabling the communal enterprises to reduce their energy expenses and, in turn, improve their services to the community.',
	},
	{
		title: 'Pilot Project Launched for Processing Organic Waste',
		year: '2019',
		description:
			'We launched a pilot project to process food and organic waste using red Californian worms (Eisenia fetida) and developed a unique algorithm for growing technological earthworms. This innovative approach not only efficiently converts organic waste into nutrient-rich biohumus for fertilizing agricultural lands, but also promotes sustainable waste management practices.',
	},
	{
		title: 'Participation in the AgroBioHeat Icebreakers initiatives',
		year: '2020',
		description:
			'In 2020, our company participated in the AgroBioHeat project, which aimed to install biomass boilers in Kherson for heating office and warehouse premises using local agro-biomass such as reeds, cuttings, and husks. The Kherson region boasts a total area of approximately 80,000 hectares covered by reeds, with an economic energy potential of 58,000 tons. Leveraging our 7+ years of experience in briquette production using reeds, sunflower husks, and wood biomass, we aimed to popularize reed harvesting in the region, with the goal of expanding our operations.',
	},
	{
		title:
			'Team Member Participates in German Ministry of Economy and Energy Program',
		year: '2021',
		description:
			'In 2021, our team member, Prokhorov Volodymyr, participated in the "Ecological Utilization of Waste" program of the German Federal Ministry of Economy and Energy. Through this program, Volodymyr gained expertise in waste management, circular economy, and environmental sustainability. His participation in the program has helped us stay up-to-date with the latest developments in waste management and provide innovative and sustainable solutions to our clients.',
	},
];

export { timelineData };
