import { ContactForm } from 'common/ContactForm/ContactForm';
import { ProductCard } from './components/ProductCard/ProductCard';
import { products } from './StoreList';
import { PageHelmet } from 'common/Helmet/Helmet';

function StoreComponent() {
	const listItems = products.map((item) => (
		<ProductCard key={item.id} product={item} />
	));
	return (
		<section className='container p-3'>
			<PageHelmet
				title='Buy Premium Worm Castings Online for Sustainable Gardening and Improved Soil'
				description='Our premium worm castings are nutrient-rich organic fertilizers that improve soil structure and boost plant growth. Order now for healthy, sustainable gardening!'
				// image={}
				url='store'
			/>
			<div className='row text-center'>
				<h2 className='mb-4'>Shop Organic, Grow Organic</h2>
				<p>
					Discover the Best Organic Fertilizers and Compost for a Healthier
					Garden
				</p>
			</div>
			<div className='container p-0 p-md-3'>
				<div className='row gy-4 d-flex justify-content-center'>
					{listItems}
				</div>
				<div className='row'>
					<ContactForm />
				</div>
			</div>
		</section>
	);
}

export { StoreComponent };
