import React from 'react';
import { Helmet } from 'react-helmet-async';

const PageHelmet = ({ title, description, image, url }) => {
	const pageTitle = title ? `${title} | Worm NET` : 'Worm NET';
	const pageDescription = description || 'This is my site description';
	// const pageImage = image || 'https://example.com/image.jpg';
	const pageUrl = `https://wormnet.pro/${url}` || 'https://wormnet.pro';

	return (
		<Helmet>
			<title>{pageTitle}</title>
			<meta name='description' content={pageDescription} />
			<meta property='og:title' content={pageTitle} />
			<meta property='og:description' content={pageDescription} />
			{/* <meta property='og:image' content={pageImage} /> */}
			<meta property='og:url' content={pageUrl} />
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:title' content={pageTitle} />
			<meta name='twitter:description' content={pageDescription} />
			{/* <meta name='twitter:image' content={pageImage} /> */}
			<link rel='canonical' href={pageUrl} />
		</Helmet>
	);
};

export { PageHelmet };
