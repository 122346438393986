import { ButtonComponent } from 'common/Button/Button';
import { InputComponent } from 'common/Input/Input';
import { ModalComponent } from 'common/ModalWindow/Modal';
import { useEffect, useState } from 'react';

function ContactForm() {
	const API_ENDPOINT = 'https://wormnet.pro/send-mail.php';
	const debugMode = false;

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');

	const [nameIsValid, setNameIsValid] = useState(false);
	const [emailIsValid, setEmailIsValid] = useState(false);

	const [formValid, setFormValid] = useState(false);
	const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

	const [nameFieldStyle, setNameFieldStyle] = useState('form-control');
	const [emailFieldStyle, setEmailFieldStyle] = useState('form-control');

	const [message, setMessage] = useState('');
	const messageHandler = (event) => {
		setMessage(event.target.value);
	};
	useEffect(() => {
		setFormValid(nameIsValid && emailIsValid);
		setButtonIsDisabled(!formValid);
	}, [nameIsValid, emailIsValid, formValid]);

	const nameHandler = (event) => {
		setName(event.target.value);
		setNameIsValid(!event.target.value ? false : true);
		setNameFieldStyle(
			!event.target.value ? 'form-control is-invalid' : 'form-control'
		);
	};

	const emailHandler = (event) => {
		setEmail(event.target.value);
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(String(event.target.value).toLowerCase())) {
			setEmailIsValid(false);
			setEmailFieldStyle('form-control is-invalid');
		} else {
			setEmailIsValid(true);
			setEmailFieldStyle('form-control');
		}
	};

	const blurHandler = (event) => {
		switch (event.target.name) {
			case 'name':
				setNameFieldStyle(
					!event.target.value ? 'form-control is-invalid' : 'form-control'
				);
				setNameIsValid(!event.target.value ? false : true);
				break;
			case 'email':
				setEmailFieldStyle(
					!event.target.value ? 'form-control is-invalid' : 'form-control'
				);
				setEmailIsValid(!event.target.value ? false : true);
				break;
			default:
				console.log('Error!');
		}
	};

	const formSent = (event) => {
		event.preventDefault();
		if (formValid) {
			fetch(API_ENDPOINT, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ name, email, message }),
			})
				.then((response) => response.json())
				.then((data) => {
					if (debugMode) {
						console.log('Form submission successful:', data);
					}
				})
				.catch((error) => {
					if (debugMode) {
						console.log('Form submission error: ', error);
					}
				})
				.finally(() => {
					openModal();
					setName('');
					setEmail('');
					setMessage('');
					setButtonIsDisabled(true);
				});
		}
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<section className='container p-3'>
			<ModalComponent isShow={isModalOpen} initModal={closeModal} />
			<form action='#' onSubmit={formSent} id='form'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-6 p-3'>
							<h2>Want to know more?</h2>
							<h4>Leave a request for a free consultation</h4>
							<p>We will contact you as soon as possible</p>
						</div>
						<div className='col-md-6 p-3 text-center'>
							<div className='form-floating'>
								<InputComponent
									inputId='name'
									thisClass={nameFieldStyle}
									placeholder='Your name'
									value={name}
									onChange={nameHandler}
									onBlur={blurHandler}
								/>
								<label htmlFor={'name'} className='form-label'>
									{'Your name'}
								</label>
							</div>
							<div className='form-floating'>
								<InputComponent
									inputId='email'
									type='email'
									thisClass={emailFieldStyle}
									placeholder='email'
									label='Your email'
									value={email}
									onChange={emailHandler}
									onBlur={blurHandler}
								/>
								<label htmlFor={'email'} className='form-label'>
									{'Your email'}
								</label>
							</div>

							<div className='form-floating'>
								<textarea
									className='form-control'
									placeholder='Leave your message here'
									id='floatingTextarea1'
									value={message}
									style={{ height: '10em' }}
									onChange={messageHandler}
								></textarea>
								<label htmlFor='floatingTextarea1'>Message</label>
							</div>

							<div className='mt-3'>
								<ButtonComponent
									type='submit'
									id='feedback'
									thisClass='btn btn-outline-success'
									btnText='Send an inquiry'
									onClick={formSent}
									disabled={buttonIsDisabled}
								/>
							</div>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
}

export { ContactForm };
