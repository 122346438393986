import { useNavigate } from 'react-router-dom';
import defaultImg from 'assets/shop/dummy.jpg';
import { ButtonComponent } from 'common/Button/Button';
import './ProductCard.css';

function ProductCard({ product }) {
	const name = product.name;
	// const description = product.description;
	const imageSrc = product.image.length ? product.image : defaultImg;
	const price = product.price;

	const btnId = product.id;
	const btnClass = 'btn btn-outline-success';
	const btnText = 'Product info';

	let navigate = useNavigate();
	const routeChange = () => {
		let path = `/store/${product.id}`;
		navigate(path);
	};

	const card = (
		<div className='col-md-6 col-lg-4 col-xl-3'>
			<div className='card product-card h-100'>
				<img src={imageSrc} className='card-img-top' alt='Logo' />
				<div className='card-body'>
					<h5 className='card-title'>{name}</h5>
				</div>
				<div className='card-footer bg-transparent border-0'>
					<h4 className='text'>{price}</h4>
					<h4 className='text'>
						<ButtonComponent
							id={btnId}
							thisClass={btnClass}
							btnText={btnText}
							onClick={routeChange}
						/>
					</h4>
				</div>
			</div>
		</div>
	);
	return card;
}

export { ProductCard };
