// import customImage from '../../../../assets/main/user.png';
import rev01 from '../../../../assets/main/rev_01.png';
import rev02 from '../../../../assets/main/rev_02.png';
import rev03 from '../../../../assets/main/rev_03.png';
import rev04 from '../../../../assets/main/rev_04.png';

const list = [
	{
		id: 1,
		name: '- Irina Strahova',
		comment:
			'The composting booster has been an incredible asset to my home. It has helped me reduce my environmental impact in a meaningful way and make some money at the same time. I highly recommend it!',
		imgSrc: rev01,
		stars: 5,
	},
	{
		id: 2,
		name: '- Valeriy Shevchenko',
		comment:
			'Before I had the composting booster I was wasting time and money by transporting food waste to a local depot. Now, thanks to the composting booster, I can process food waste right at home and turn it into natural fertilizer, reducing my carbon footprint!',
		imgSrc: rev02,
		stars: 5,
	},
	{
		id: 3,
		name: '- Alena Kirkilan',
		comment:
			'Thanks to the composting booster, I have been able to reduce my food waste and start getting paid for it! It has been a great way to supplement my income and help the environment at the same time.',
		imgSrc: rev03,
		stars: 5,
	},
	{
		id: 4,
		name: '- Alexander Marisay',
		comment:
			"Before I discovered the composting booster, I had no idea I could make money out of my food waste. Now, I am able to make a significant return by turning my waste into something useful. It's amazing!",
		imgSrc: rev04,
		stars: 5,
	},
];

export { list };
