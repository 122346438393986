const benefitsList = [
	{
		logo: '5x',
		text: 'Creating nutrient-rich soil 5 times faster than conventional composting',
	},
	{
		logo: 'Water saving',
		text: 'Conservation and retention of water by improving soil structure.',
	},
	{
		logo: '25%',
		text: 'Increases yields by 25% by improving nutrient uptake by plants.',
	},
	{ logo: '30%', text: 'Accelerates seed germination by 30%.' },
];

export { benefitsList };
