import { EnvelopeAtFill } from 'react-bootstrap-icons';

function FooterComponent() {
	const footer = (
		<footer className='jumbotron m-0 p-4 px-lg-5 bg-light'>
			<div className='container'>
				<h5>
					&quot;WormNET&quot; &copy; 2019 - 2023 <br />
					Biomass and organic waste processing network
				</h5>
				<div>
					Address: Kyiv, Parkovo-Syretska str.
					<br />
					<EnvelopeAtFill />{' '}
					<span>
						<a href='mailto:&#104;&#101;&#097;&#100;&#111;&#102;&#102;&#105;&#099;&#101;&#046;&#100;&#111;&#098;&#114;&#111;&#098;&#117;&#100;&#064;&#117;&#107;&#114;&#046;&#110;&#101;&#116;'>
							&#104;&#101;&#097;&#100;&#111;&#102;&#102;&#105;&#099;&#101;&#046;&#100;&#111;&#098;&#114;&#111;&#098;&#117;&#100;&#064;&#117;&#107;&#114;&#046;&#110;&#101;&#116;
						</a>
					</span>
					<br />
				</div>
			</div>
		</footer>
	);
	return footer;
}

export { FooterComponent };
