import { Map, Mailbox, Telegram } from 'react-bootstrap-icons';

function ContactsData() {
	return (
		<div className='container p-3'>
			<div className='row'>
				<h3>Contact information</h3>
				<p>We're open for any suggestion or just to have a chat</p>
			</div>
			<div className='row'>
				<ul className='d-grid gap-3' style={{ listStyleType: 'none' }}>
					<li>
						<Map /> Address: Kyyivsʹka obl., smt Kalynivka, vul. Ihoreva
					</li>
					<li>
						<Mailbox /> Email:{' '}
						<a href='mailto:&#104;&#101;&#097;&#100;&#111;&#102;&#102;&#105;&#099;&#101;&#046;&#100;&#111;&#098;&#114;&#111;&#098;&#117;&#100;&#064;&#117;&#107;&#114;&#046;&#110;&#101;&#116;'>
							&#104;&#101;&#097;&#100;&#111;&#102;&#102;&#105;&#099;&#101;&#046;&#100;&#111;&#098;&#114;&#111;&#098;&#117;&#100;&#064;&#117;&#107;&#114;&#046;&#110;&#101;&#116;
						</a>
					</li>
					<li>
						<Telegram /> Telegram:{' '}
						<a href='https://t.me/WormNET' target='_blank' rel='noreferrer'>
							@WormNET
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}

export { ContactsData };
