import img from 'assets/404.jpg';

export const PageNotFound = () => {
	return (
		<div className='container-fluid p-3 text-center'>
			<h3>This page is under construction</h3>
			<img src={img} alt='Logo' className='img-fluid' />
		</div>
	);
};
