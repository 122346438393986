import { useNavigate } from 'react-router-dom';
import { services } from './OurServicesList';
import './OurServices.css';
import { ButtonComponent } from 'common/Button/Button';

const CardItem = ({ card }) => {
	const btnClass = 'btn btn-success';

	let navigate = useNavigate();
	const routeChange = () => {
		let path = `/store`;
		navigate(path);
	};

	return (
		<div className='col-md-12 col-lg-4'>
			<div className='py-3'>
				<h4 className='text-center'>{card.title}</h4>
				<cite className='text-secondary'>&quot;{card.subtitle}&quot;</cite>
			</div>
			<div className='image-container'>
				<img className='img-fluid' src={card.imgSrc} alt='...' />
				<div className='overlay'>
					<div className='inner-text'>
						<p>{card.description}</p>
						<ButtonComponent
							id={card.btnId}
							thisClass={btnClass}
							btnText={card.btnText}
							onClick={routeChange}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const OurServices = () => {
	const listItems = services.map((item) => (
		<CardItem key={item.id} card={item} />
	));
	const servicesBlock = (
		<section className='container p-3'>
			<div className='text-center'>
				<h2 className='mb-4'>Services we provide</h2>
			</div>
			<div className='row'>{listItems}</div>
		</section>
	);
	return servicesBlock;
};

export { OurServices };
