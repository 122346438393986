import { Team } from './components/Team/Team';
import { Timeline } from './components/Timeline/Timeline';
import { PageHelmet } from 'common/Helmet/Helmet';

function AboutUs() {
	return (
		<div className='container p-3 content-row'>
			<PageHelmet
				title='About Us - Learn More About Our Company and Mission'
				description='Discover the story behind our company and our mission to provide sustainable solutions for a better future'
				// image={}
				url='about-us'
			/>
			<div className='row'>
				<Team />
			</div>
			<div className='row'>
				<Timeline />
			</div>
		</div>
	);
}

export { AboutUs };
