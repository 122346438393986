import { ContactForm } from 'common/ContactForm/ContactForm';
import { Link, useParams } from 'react-router-dom';
import { Fragment } from 'react';
import { PageHelmet } from 'common/Helmet/Helmet';

function ProductInfo({ productsList }) {
	const { productId } = useParams();
	const currentProduct = productsList.find((item) => item.id === productId);

	if (!currentProduct) {
		return (
			<div className='container p-3'>
				<div className='row small'>
					<Link to={'/'}>&lt; Back to home page</Link>
				</div>
				<div className='d-flex align-items-center justify-content-center p-3'>
					<div className='m-5 p-3 text-center border border-warning rounded'>
						<h3>Oops!</h3>
						<p>We can't seem to find the page you're looking for.</p>
						<p>Error code: 404</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='container p-3'>
			<PageHelmet
				title={currentProduct.ceoTitle}
				description={currentProduct.ceoDescription}
				image={currentProduct.ceoImage}
				url={currentProduct.ceoUrl}
			/>
			<div className='row small'>
				<Link to={'/store'}>&lt; Back to Shop page</Link>
			</div>

			<div className='row p-3'>
				<div>
					<h1>
						{currentProduct.name} {currentProduct.price}/{currentProduct.unit}
					</h1>
				</div>
			</div>
			<div className='row p-3'>
				<div className='col-xl-5 p-0 p-sm-3'>
					<img
						src={currentProduct.image}
						className='mx-auto d-block img-fluid border'
						alt={currentProduct.name}
					/>
				</div>
				<div className='col-xl-7 p-0 p-sm-3'>
					<div>
						<h3>Product description</h3>
						{Array.isArray(currentProduct.description) ? (
							<Fragment>
								{currentProduct.description.map((desc, index) => (
									<p key={index}>{desc}</p>
								))}
							</Fragment>
						) : (
							<p>{currentProduct.description}</p>
						)}
					</div>
					<div>
						{Array.isArray(currentProduct.features) && (
							<Fragment>
								<h3>Features</h3>
								<ul>
									{currentProduct.features.map((feature) => (
										<li key={feature}>{feature}</li>
									))}
								</ul>
							</Fragment>
						)}
					</div>
				</div>
			</div>
			{currentProduct.table && (
				<div className='row'>
					<table className='table table-striped'>
						<thead>
							<tr>
								{currentProduct.table[0].map((header, colIndex) => (
									<th key={colIndex}>{header}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{currentProduct.table.slice(1).map((row, rowIndex) => (
								<tr key={rowIndex}>
									{row.map((col, colIndex) => (
										<td key={colIndex}>{col}</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
			<div className='row'>
				<ContactForm />
			</div>
		</div>
	);
}

export { ProductInfo };
