import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';

import { FooterComponent } from 'components/Footer/Footer';
import { HeaderComponent } from 'components/Header/Header';
import { StoreComponent } from 'pages/Store/Store';
import { AboutUs } from 'pages/Team/AboutUs';
import { PageNotFound } from 'pages/404/404';
import { MainPage } from 'pages/Home/Home';
import { ProductInfo } from 'pages/Store/components/ProductInfo/ProductInfo';
import { Contacts } from 'pages/Contacts/Contacts';
import { products } from 'pages/Store/StoreList';

import './App.css';

function App() {
	return (
		<HelmetProvider>
			<div className='container-fluid p-0'>
				<HeaderComponent />
				<main className='container-fluid p-0'>
					<Routes>
						<Route path='/' element={<MainPage />} />
						<Route path='/store' element={<StoreComponent />} />
						<Route
							path='/store/:productId'
							element={<ProductInfo productsList={products} />}
						/>
						<Route path='/about-us' element={<AboutUs />} />
						<Route path='/contact' element={<Contacts />} />
						<Route path='/404' element={<PageNotFound />} />
						<Route path='*' element={<PageNotFound />} />
					</Routes>
				</main>
				<FooterComponent />
			</div>
		</HelmetProvider>
	);
}

export default App;
