function SingleCard({ user }) {
	const userImage = user.imgSrc;
	const userName = user.name;
	const userComment = user.comment;
	const card = (
		<div className='col-md-6 mb-4 mb-md-3'>
			<div className='d-flex justify-content-center mb-4'>
				<img
					src={userImage}
					className='rounded-circle shadow-1-strong'
					width='100'
					height='100'
					alt='...'
				/>
			</div>
			<p className='lead my-3 text-muted'>&quot;{userComment}&quot;</p>
			<p className='font-italic font-weight-normal mb-0'>{userName}</p>
		</div>
	);
	return card;
}

export { SingleCard };
