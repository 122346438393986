import { TeamCard } from './TeamCard';
import { TeamList } from './TeamList';

function Team() {
	const teamList = TeamList.map((member) => (
		<TeamCard key={member.id} member={member} />
	));
	const teamBlock = (
		<section className='row p-3'>
			<div className='container'>
				<div className='row d-flex justify-content-center'>
					<div className='col-md-10 col-xl-8 text-center'>
						<h2 className='mb-4'>Our team</h2>
						<p className='mb-4 pb-2 mb-md-5 pb-md-0'>
							"Our Greatest Asset: Meet the Talented and Dedicated Team Behind
							Our Company's Success"
						</p>
					</div>
				</div>
				<div className='row'>{teamList}</div>
			</div>
		</section>
	);
	return teamBlock;
}

export { Team };
