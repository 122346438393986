import React from 'react';

function InputComponent(props) {
	return (
		<input
			type={props.type ? props.type : 'text'}
			id={props.inputId}
			name={props.inputId}
			value={props.value ? props.value : ''}
			placeholder={props.placeholder}
			className={props.thisClass}
			onChange={props.onChange}
			onBlur={props.onBlur}
			autoComplete='off'
		></input>
	);
}

export { InputComponent };
