import './Home.css';
import React from 'react';
import { BenefitsBlock } from './components/Benefits/Benefits';
import { TestemonialsBlock } from './components/testimonials/Testimonials';
import { ContactForm } from 'common/ContactForm/ContactForm';
import { HeadlineBlock } from './components/Headline/Headline';
import { FAQ } from './components/FAQ/FAQ';
import { OurServices } from './components/OurServices/OurServices';
import { PageHelmet } from 'common/Helmet/Helmet';

function MainPage() {
	const oddBlock = 'container-fluid p-0 px-md-3';
	const evenBlock = 'container-fluid p-0 px-md-3 text-bg-light';
	const innerBlock = 'container p-0 px-md-3';
	return (
		<div className='container-fluid p-0'>
			<PageHelmet
				title='Boost Your Soil Health with Paste Composting Boosters | Worm NET'
				description='Essenya Fetida red Californian worm for soil health. Recycling organic and food waste with worm extract composting booster'
				// image={}
				url=''
			/>
			<div className={oddBlock}>
				<div className={innerBlock}>
					<HeadlineBlock />
				</div>
			</div>
			<div className={evenBlock}>
				<div className={innerBlock}>
					<BenefitsBlock />
				</div>
			</div>
			<div className={oddBlock}>
				<div className={innerBlock}>
					<OurServices />
				</div>
			</div>
			<div className={evenBlock}>
				<div className={innerBlock}>
					<TestemonialsBlock />
				</div>
			</div>
			<div className={oddBlock}>
				<div className={innerBlock}>
					<FAQ />
				</div>
			</div>
			<div className={evenBlock}>
				<div className={innerBlock}>
					<ContactForm />
				</div>
			</div>
		</div>
	);
}

export { MainPage };
