import { ButtonComponent } from 'common/Button/Button';
import { useNavigate } from 'react-router-dom';

const CallToAction = () => {
	const btnId = 'callToAction';
	const btnClass = 'btn btn-outline-success';
	const btnText = 'Know how';

	let navigate = useNavigate();
	const routeChange = () => {
		let path = '/store';
		navigate(path);
	};

	return (
		<ButtonComponent
			id={btnId}
			thisClass={btnClass}
			btnText={btnText}
			onClick={routeChange}
		/>
	);
};

export { CallToAction };
