import product from '../../../../assets/main/product.png';
import { CallToAction } from '../CallToAction/CallToAction';

function HeadlineBlock({ thisClass }) {
	const headlineBlock = (
		<section className='row p-3'>
			<div className='col-lg-6'>
				<img
					className='mx-auto d-block img-fluid'
					src={product}
					alt='Product'
				/>
			</div>
			<div className='col-lg-6 p-4 d-flex flex-column justify-content-evenly'>
				<h1 className='headline'>
					Make money with composting booster Worm&nbsp;NET
				</h1>
				<h4>
					Revitalize your soil with earthworm power: recycling organic waste for
					natural fertilization! Save money with composting booster and get
					organic fertilizers for free!
				</h4>
				<p>
					<CallToAction />
				</p>
			</div>
		</section>
	);
	return headlineBlock;
}

export { HeadlineBlock };
