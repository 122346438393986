import { SingleCard } from './card/card';
import { list } from './Testemonials';

function TestemonialsBlock() {
	const cardList = list.map((item) => <SingleCard key={item.id} user={item} />);
	const testimonialsBlock = (
		<section className='row p-3'>
			<div className='row d-flex justify-content-center'>
				<div className='col-md-10 col-xl-8 text-center'>
					<h2 className='mb-4'>Our Clients</h2>
					<p className='mb-4 pb-2 mb-md-5 pb-md-0'>
						We believe in client satisfaction. Here are some testimonials by our
						worthy clients.
					</p>
				</div>
			</div>
			<div className='row text-center'>{cardList}</div>
		</section>
	);
	return testimonialsBlock;
}

export { TestemonialsBlock };
