import { CallToAction } from '../CallToAction/CallToAction';
import { benefitsList } from './BenefitsList';

function BenefitsBlock() {
	const listItems = benefitsList.map((item, index) => (
		<div className='col-md-3' key={index}>
			<h3 className='text-success text-center'>{item.logo}</h3>
			<p>{item.text}</p>
		</div>
	));
	const benefitsBlock = (
		<section className='row p-3'>
			<div>
				<h2 className='text-center pb-3'>
					Key benefits of our Composting booster
				</h2>
			</div>
			<div className='row'>{listItems}</div>
			<div className='text-center p-3'>
				<CallToAction />
			</div>
		</section>
	);
	return benefitsBlock;
}

export { BenefitsBlock };
