import farming from '../../../../assets/main/farming.webp';
import gardening from '../../../../assets/main/gardening.webp';
import municipal from '../../../../assets/main/municipal.webp';

const services = [
	{
		id: '1',
		title: 'For Municipalities',
		subtitle: 'Let Earthworms do the dirty work for you!',
		description:
			'Using worms to process food waste can reduce total waste by 40%, simplify sorting, and turn the remaining waste into recyclable or sellable material. Plus, it is an environmentally friendly way to fertilize your yard for free.',
		imgSrc: municipal,
		btnText: 'More info',
	},
	{
		id: '2',
		title: 'For Agriculture',
		subtitle: 'Use Earthworms as master creators of fertilizing the soil.',
		description:
			'Earthworms improve soil structure, aerate and increase water retention. They also break down organic matter, releasing nutrients rich in nitrogen, phosphorus and potassium. The soil rich in worm enzymes accelerates seed germination, facilitates the absorption of nutrients by the root system, which makes the plant resistant to stress.',
		imgSrc: farming,
		btnText: 'Learn more',
	},
	{
		id: '3',
		title: 'For Home and Garden',
		subtitle: 'Turn your garden into an eco oasis with earthworms!',
		description:
			"Our product, based on age-old vermiculture technology, makes recycling incredibly easy and creates a nutrient-rich fertilizer for your plants. Simply add your kitchen waste and let our booster do the rest. You'll also save money on buying expensive fertilizer, as well as reduce your carbon footprint.",
		imgSrc: gardening,
		btnText: 'Buy now',
	},
];

export { services };
