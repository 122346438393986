import { ContactForm } from 'common/ContactForm/ContactForm';
import { ContactsData } from './Components/Contacts/ContactsData';
import { PageHelmet } from 'common/Helmet/Helmet';

function Contacts() {
	return (
		<section className='container p-3'>
			<PageHelmet
				title='Get in Touch with Our Team Today'
				description='Have questions or need assistance? Contact us today and our friendly team will be happy to help you with your inquiries about our products and services'
				// image={}
				url='contact'
			/>
			<div className='row text-center'>
				<h2 className='mb-4'>Get in Touch</h2>
				<p>
					Contact us for inquiries, feedback, or to learn more about our
					services.
				</p>
			</div>
			<div className='row'>
				<div className='col-md-5'>
					<ContactsData />
				</div>
				<div className='col-md-7'>
					<ContactForm />
				</div>
			</div>
		</section>
	);
}

export { Contacts };
