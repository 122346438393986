import worm from '../../assets/shop/worms.jpg';
import booster from '../../assets/shop/booster.webp';
import composter from '../../assets/shop/composter.webp';
import vermicompost from '../../assets/shop/vermicompost.webp';
import GreenSoil from '../../assets/shop/gumohiton.jpg';
import vermitea from '../../assets/shop/vermi-tea.webp';

const products = [
	{
		id: 'compost-recycler',
		name: 'Compost recycler extract vermiculture',
		description: [
			'Introducing Worm NET Composting Booster - the ultimate solution for accelerated composting of organic residues. This product is specially formulated with red California worm extract, which helps to speed up the composting process and create valuable humus for your garden.',
			'To prepare the solution, simply measure the required dose of the booster according to the table and add it to settled non-chlorinated, unfiltered water at room temperature. Then, stir at short intervals to enrich the mixture with oxygen and let it infuse for 30 minutes. Finally, pour the liquid concentrate evenly onto the organic material prepared for composting with a layer thickness of 15 to 20cm.',
			"Worm NET Composting Booster is suitable for processing various organic materials, including waste from plants, food waste, paper, and cardboard. However, it's important to avoid adding meat, fat, and bones to the compost, as they decompose slowly and can attract pests.",
			'Using Worm NET Composting Booster to process your compost will result in the production of valuable humus, which is an excellent organic fertilizer that can increase yields by 25-50%. It also accelerates the growing season by 1-2 weeks and contains nutrients that are easily absorbed by plants. Plus, it saves labor costs for crop care due to the absence of weeds.',
			"Don't wait any longer to try Worm NET Composting Booster and take your composting to the next level. Accelerate the process and create nutrient-rich humus for your garden today!",
		],
		table: [
			[
				'Amount of organic material intended for composting',
				'Amount of Worm NET Composting Booster',
				'The amount of water for preparing the solution',
			],
			['1 cubic meter', '200 grams', '10 liters '],
			['1 sq.m. height from 15 cm to 20 cm', '30 grams ', '2 liters'],
			['2 cubic meters', '400 grams', '20 liters'],
			['2 square meters, height from 15 cm to 20 cm', '60 grams', '4 liters'],
			['3 cubic meters ', '600 grams', '30 liters'],
			['3 square meters, height from 15 cm to 20 cm', '90 grams', '6 liters'],
		],
		image: booster,
		price: '$5.00',
		unit: 'kilo',
		ceoTitle:
			'Accelerate organic composting with Worm NET Composting Booster, the ultimate solution made with red California worm extract',
		ceoDescription:
			'Speed up your composting process and create valuable humus for your garden with Worm NET Composting Booster. Formulated with red California worm extract for ultimate results',
		ceoImage: booster,
		ceoUrl: 'compost-recycler',
	},
	{
		id: 'starter-kit',
		name: 'Stress-Free Compost Worms Starter Kit with Bedding',
		description:
			'Looking to start your own worm farm at home, school, or even in your office? Look no further than our all-in-one compost worms starter pack! Our packs come ready to go with everything you need to get started, including our specially raised and hand-selected compost worms and their cozy bedding. No additional bedding, such as coconut fiber or shredded paper, is required. With our starter pack, you can rest assured that both you and your new compost worms will have a stress-free start to your journey together. Once ordered, our worms are gently hand-lifted from our worm beds and immediately packaged with their bedding, ready to move into their new home as soon as they arrive at your doorstep.',
		features: [
			'All-in-one starter pack',
			'Specially raised compost worms',
			'Stress-free worm farming',
		],
		image: worm,
		price: '$30.00',
		unit: 'kilo',
		ceoTitle:
			'Get everything you need to start worm farming without any stress with our All-in-One Compost Worms Starter Pack',
		ceoDescription:
			'Get everything you need to start worm farming at home, school, or office with our all-in-one compost worms starter pack. Specially raised and hand-selected worms for a stress-free start',
		ceoImage: worm,
		ceoUrl: 'starter-kit',
	},
	{
		id: 'vermicompost',
		name: 'Pure Organic Vermicompost (manure free)',
		description:
			'Vermigumus is a premium organic fertilizer that is made using a unique and patented process. It is produced by feeding organic waste to red California worms, which break down the waste and produce a nutrient-rich and microbe-filled compost. This compost, also known as biohumus, is an excellent soil amendment that can help restore soil health and fertility. Unlike many conventional fertilizers, vermigumus is completely natural and does not contain any inorganic chemicals or synthetic additives. This makes it a safe and sustainable option for gardeners, farmers, and anyone looking to improve soil quality without harming the environment.',
		features: [
			'Enhanced plant growth: Vermigumus is rich in essential nutrients like nitrogen, phosphorus, and potassium, which can promote healthy plant growth and development',
			'Increased soil fertility: The microorganisms in vermigumus can help break down organic matter in the soil, improving soil structure and fertility',
			'Reduced environmental impact: Because it is made from organic waste and does not contain any synthetic chemicals, vermigumus is a more sustainable and eco-friendly fertilizer option',
			'Improved plant resistance: Vermigumus has been shown to improve plant resistance to pests and diseases, helping to protect crops and gardens naturally',
			'Better taste and nutrition: When used to fertilize fruits and vegetables, vermigumus can help improve their taste and nutritional value',
		],
		image: vermicompost,
		price: '$1.00',
		unit: 'kilo',
		ceoTitle: 'The Natural Solution for Healthy Soil | Buy Now',
		ceoDescription:
			'Vermigumus is an all-natural soil amendment that improves soil fertility and plant growth without any harmful chemicals. Buy Vermigumus today and restore your soils health naturally',
		ceoImage: vermicompost,
		ceoUrl: 'vermicompost',
	},
	{
		id: 'vermi-tea',
		name: 'Pure and Organic Vermicompost for Natural Plant Nutrition',
		description:
			'Our liquid vermicompost is a highly concentrated and organic extract made exclusively from pure food and organic waste, without the use of animal products. It contains live microflora, trace and macro elements, humates, fulvic acids, amino acids, phytohormones, enzymes, vitamins, and growth hormones that provide a safe and natural environment for plants and soil. Use it for pre-sowing treatment, foliar fertilization, and to increase yield, nutritional content, and protection against diseases and pests. Our vermicompost has a high content of humic substances, is safe for all living organisms, and eliminates the need for harmful pesticides and fertilizers. With a shelf life of 3 years, you can contribute to the preservation of the environment while feeding your plants naturally.',
		features: [
			'Made exclusively from pure food and organic waste without animal products',
			'Contains live microflora, trace and macro elements, humates, fulvic acids, amino acids, phytohormones, enzymes, vitamins, and growth hormones',
			'Safe and natural environment for plants and soil',
			'Eliminates the need for harmful pesticides and fertilizers',
			'Helps reduce carbon and methane emissions by reducing the negative impact of the greenhouse effect',
		],
		image: vermitea,
		price: '$1.00',
		unit: 'liter',
		ceoTitle:
			'Natural Plant Feed with Vermicompost from Pure Organic Waste - Instructions for Use Included',
		ceoDescription:
			'Feed your plants naturally with our vermicompost made exclusively from pure food and organic waste without using animal products. Our liquid vermicompost contains live microflora, trace and macro elements, humates, and more. It is safe, natural, and completely organic for plants and soil. Follow our instructions for pre-sowing treatment, foliar fertilization, and storage to enhance your plant growth. By purchasing our vermicompost, you help preserve the environment by reducing the volume of landfill waste and greenhouse gas emissions',
		ceoImage: vermitea,
		ceoUrl: 'vermi-tea',
	},
	{
		id: 'green-soil-restorer',
		name: 'GreenSoil Restorer',
		description:
			'Our product is a natural and organic solution for restoring soil health and fertility without the use of any harmful inorganic chemicals. It is specifically designed to promote plant growth, improve soil structure, and increase nutrient availability in a safe and environmentally-friendly way. Our unique formula contains a blend of natural ingredients that work together to stimulate microbial activity, improve soil texture, and enhance nutrient uptake by plants. By using our product, you can rest assured that you are not only improving the quality of your soil but also contributing to a healthier and more sustainable environment for future generations. Join the growing number of farmers, gardeners, and homeowners who are choosing our product for their soil restoration needs and experience the benefits of a truly natural solution.',
		features: [
			'Organic and Chemical-Free: Our soil restoration product is 100% organic and does not contain any inorganic chemicals, ensuring that your soil is free from harmful synthetic substances',
			'Natural Nutrient Boost: Our product contains a rich blend of organic materials, including worm castings and compost, to provide your soil with the natural nutrients it needs to thrive',
			'Enhances Soil Health: Our product works to improve the overall health of your soil, promoting the growth of healthy plants and crops, while reducing the risk of pests and diseases',
			'Environmentally Friendly: By using our natural soil restoration product, you are helping to reduce the negative impact of synthetic fertilizers and chemicals on the environment, making it a more sustainable and eco-friendly option',
			'Easy to Use: Our soil restoration product is simple and easy to use. Just apply it to your soil as directed, and watch as your plants and crops flourish with natural and healthy growth',
		],
		image: GreenSoil,
		price: '$4.00',
		unit: 'liter',
		ceoTitle: 'Natural Soil Restoring Solution | Chemical-Free Soil Amendment',
		ceoDescription:
			'Our product offers a natural solution for restoring soil without the use of any harmful inorganic chemicals. Our soil amendment is made from organic materials and helps to improve soil health, increase plant growth and yield, and reduce environmental impact. Order now for a chemical-free way to restore your soil and promote sustainable agriculture',
		ceoImage: GreenSoil,
		ceoUrl: 'green-soil-restorer',
	},
	{
		id: 'rotary-vermicomposter',
		name: 'Rotary Vermicomposter 50\u00A0Ltr - 300\u00A0Ltr',
		description:
			'		Utilizing cutting-edge techniques in our advanced manufacturing facility, we present our Rotary Drum Composters, engineered to produce fast and reliable outcomes. Our composters are made of premium quality UV-stabilized polyethylene, ensuring rust-free and long-lasting performance. By using our drum composter, you can easily transform your waste into valuable and nutrient-rich soil. Prior to release, each composter undergoes rigorous testing by our quality control team to guarantee its flawless operation.',
		features: [
			'Mounted on a stand at a distance from the ground',
			'Bearing on the stand and handles for easy turning',
			'Air vents for sufficient oxygen supply',
			'Available in various sizes - 50Ltr, 100 Ltr, 200 Ltr, 300 Ltr',
			'Aesthetically pleasing and user-friendly',
		],
		image: composter,
		price: '$200.00',
		unit: 'piece',
		ceoTitle: 'Transform your waste fast and reliably with our Drum Composters',
		ceoDescription:
			'Transform waste into nutrient-rich soil with our premium quality Rotary Vermicomposter made of UV-stabilized polyethylene. Available in various sizes and user-friendly features',
		ceoImage: composter,
		ceoUrl: 'rotary-vermicomposter',
	},
];

export { products };
