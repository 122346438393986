import { Facebook, Linkedin } from 'react-bootstrap-icons';

function TeamCard({ member }) {
	const name = member.name;
	const title = member.title;
	const imgLink = member.imgLink;
	const facebook = member.facebook;
	const linkedin = member.linkedin;

	const card = (
		<div className='col-md-6 col-xl-4 d-flex align-items-stretch'>
			<div className='card text-center p-4 mb-4'>
				<img className='card-img-top' src={imgLink} alt='Наша команда' />
				<div className='card-body'>
					<h5 className='card-title'>{name}</h5>
					<h6 className='card-subtitle mb-2 text-muted'>{title}</h6>
				</div>
				<div className='card-footer text-muted border-0 bg-transparent d-flex justify-content-evenly'>
					{facebook ? (
						<a
							href={facebook}
							className='btn btn-secondary'
							title='Facebook page'
							target='_blank'
							rel='noreferrer'
						>
							<Facebook /> Facebook
						</a>
					) : null}
					{linkedin ? (
						<a
							href={linkedin}
							className='btn btn-secondary'
							title='Linkedin page'
							target='_blank'
							rel='noreferrer'
						>
							<Linkedin /> Linkedin
						</a>
					) : null}
				</div>
			</div>
		</div>
	);

	return card;
}

export { TeamCard };
